// src/App.js
// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Start from './pages/Start';
import Projects from './pages/Projects';
import Services from './pages/Services';
import HowWeWork from './pages/HowWeWork';
import About from './pages/About';
import Team from './pages/Team';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import DataDeletionRequest from './pages/DataDeletion';
import Cookies from './pages/Cookies';
import Feedback from './pages/Feedback';  // Add this line
import { ChakraProvider } from '@chakra-ui/react';

// Create a Material UI theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#264653',
    },
    secondary: {
      main: '#e76f51',
    },
    background: {
      default: '#ffff',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

function MainContent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <main style={{ 
      flexGrow: 1, 
      paddingTop: isMobile ? '56px' : '56px', // AppBar is typically 56px on mobile and 64px on desktop
      minHeight: '80vh'
    }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/start" element={<Start />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/services" element={<Services />} />
        <Route path="/how-we-work" element={<HowWeWork />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<Team />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/data-deletion" element={<DataDeletionRequest />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/feedback" element={<Feedback />} />  {/* Add this line */}
      </Routes>
    </main>
  );
}

function App() {
  return (
    <ChakraProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline /> {/* This resets and normalizes CSS across browsers */}
        <Router>
          <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <MainContent />
            <Footer />
          </div>
        </Router>
      </ThemeProvider>
    </ChakraProvider>
  );
}

export default App;
