// src/components/Footer.js

import React from 'react';
import { Box, Text, Flex, Link, Icon, VStack, HStack, Divider } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, InfoIcon } from '@chakra-ui/icons';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaGithub } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

function Footer() {
  return (
    <Box bg="gray.800" color="gray.300" py={10}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        maxW="1200px"
        mx="auto"
        px={8}
        justify="space-between"
      >
        <VStack align="start" mb={{ base: 8, md: 0 }}>
          <Text fontSize="xl" fontWeight="bold" mb={2}>
            CurrentlyRemote.com
          </Text>
          <Text fontSize="sm">Empowering remote work since 2024</Text>
          <HStack spacing={4} mt={4}>
            <Link href="#" isExternal><Icon as={FaFacebookF} w={5} h={5} /></Link>
            <Link href="#" isExternal><Icon as={FaTwitter} w={5} h={5} /></Link>
            <Link href="#" isExternal><Icon as={FaLinkedinIn} w={5} h={5} /></Link>
            <Link href="#" isExternal><Icon as={FaGithub} w={5} h={5} /></Link>
          </HStack>
        </VStack>

        <VStack align="start" mb={{ base: 8, md: 0 }}>
          <Text fontWeight="bold" mb={2}>Quick Links</Text>
          <Link href="/" fontSize="sm">Home</Link>
          <Link href="/about" fontSize="sm">About Us</Link>
          <Link href="/services" fontSize="sm">Services</Link>
          <Link href="/contact" fontSize="sm">Contact</Link>
        </VStack>

        <VStack align="start" mb={{ base: 8, md: 0 }}>
          <Text fontWeight="bold" mb={2}>Legal</Text>
          <Link href="/terms" fontSize="sm">Terms of Service</Link>
          <Link href="/privacy-policy" fontSize="sm">Privacy Policy</Link>
          <Link href="/data-deletion" fontSize="sm">Data Deletion</Link>
          <Link href="/cookies" fontSize="sm">Cookie Policy</Link>
        </VStack>

        <VStack align="start">
          <Text fontWeight="bold" mb={2}>Contact Us</Text>
          <HStack>
            <Icon as={EmailIcon} />
            <Link href="mailto:support@currentlyremote.com" fontSize="sm">
              support@currentlyremote.com
            </Link>
          </HStack>
          <HStack>
            <Icon as={PhoneIcon} />
            <Text fontSize="sm">+1 (555) 123-4567</Text>
          </HStack>
          <HStack>
            <Icon as={InfoIcon} />
            <Text fontSize="sm">123 Remote St, Digital City, 12345</Text>
          </HStack>
        </VStack>
      </Flex>

      <Divider my={6} borderColor="gray.600" />

      <Text fontSize="sm" textAlign="center">
        &copy; {new Date().getFullYear()} CurrentlyRemote.com. All rights reserved.
      </Text>

    </Box>
  );
}

export default Footer;
