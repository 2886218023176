import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function Cookies() {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Cookie Policy
      </Typography>
      <Typography variant="body1" paragraph>
        This Cookie Policy explains how CurrentlyRemote.com uses cookies and similar technologies to recognize you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control our use of them.
      </Typography>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>What are cookies?</Typography>
        <Typography variant="body1" paragraph>
          Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>Why do we use cookies?</Typography>
        <Typography variant="body1" paragraph>
          We use cookies for the following purposes:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="To enable certain functions of the Service" />
          </ListItem>
          <ListItem>
            <ListItemText primary="To provide analytics" />
          </ListItem>
          <ListItem>
            <ListItemText primary="To store your preferences" />
          </ListItem>
          <ListItem>
            <ListItemText primary="To enable advertisements delivery, including behavioral advertising" />
          </ListItem>
        </List>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>How can I control cookies?</Typography>
        <Typography variant="body1" paragraph>
          You have the right to decide whether to accept or reject cookies. You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted.
        </Typography>
      </Box>
      <Typography variant="body2" color="text.secondary">
        Last updated: {new Date().toLocaleDateString()}
      </Typography>
    </Container>
  );
}

export default Cookies;
