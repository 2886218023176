import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, CardActions, Button } from '@mui/material';

const team = [
  {
    name: "John Doe",
    role: "Founder & CEO",
    image: "/images/john-doe.jpg",
    bio: "John has over 15 years of experience in software development and business management."
  },
  {
    name: "Jane Smith",
    role: "CTO",
    image: "/images/jane-smith.jpg",
    bio: "Jane is a tech visionary with a passion for cutting-edge technologies and innovation."
  },
  {
    name: "Mike Johnson",
    role: "Lead Developer",
    image: "/images/mike-johnson.jpg",
    bio: "Mike is an expert in full-stack development with a focus on scalable architectures."
  },
  {
    name: "Sarah Brown",
    role: "UX/UI Designer",
    image: "/images/sarah-brown.jpg",
    bio: "Sarah brings creativity and user-centric design principles to every project."
  },
  {
    name: "David Lee",
    role: "Project Manager",
    image: "/images/david-lee.jpg",
    bio: "David ensures smooth project execution with his excellent organizational skills."
  },
  {
    name: "Emily Chen",
    role: "Marketing Specialist",
    image: "/images/emily-chen.jpg",
    bio: "Emily drives our marketing efforts, helping clients achieve maximum visibility."
  }
];

function Team() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Our Team
      </Typography>
      <Typography variant="body1" paragraph>
        Meet the talented individuals behind CurrentlyRemote.com. Our diverse team brings a wealth of experience and expertise to every project.
      </Typography>
      <Grid container spacing={4}>
        {team.map((member, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="240"
                image={member.image}
                alt={member.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {member.name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                  {member.role}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {member.bio}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Learn More</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Team;
