import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function Privacy() {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Privacy Policy
      </Typography>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>1. Information We Collect</Typography>
        <Typography variant="body1" paragraph>
          We collect information you provide directly to us, such as when you create an account, submit a form, or communicate with us. This may include your name, email address, and any other information you choose to provide.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>2. How We Use Your Information</Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to comply with legal obligations.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>3. Information Sharing and Disclosure</Typography>
        <Typography variant="body1" paragraph>
          We do not sell your personal information. We may share your information with third-party service providers who perform services on our behalf, or when required by law.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>4. Data Security</Typography>
        <Typography variant="body1" paragraph>
          We implement appropriate technical and organizational measures to protect the security of your personal information.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>5. Your Rights</Typography>
        <Typography variant="body1" paragraph>
          You have the right to access, correct, or delete your personal information. You may also have the right to restrict or object to certain processing of your data.
        </Typography>
      </Box>
      <Typography variant="body2" color="text.secondary">
        Last updated: {new Date().toLocaleDateString()}
      </Typography>
    </Container>
  );
}

export default Privacy;
