import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import { SecurityOutlined as SecurityIcon } from '@mui/icons-material';

const steps = [
  {
    label: 'Provide Your Information',
    description: 'We need your email to identify your data.',
  },
  {
    label: 'Confirm Data Deletion',
    description: 'Please specify which data you want to be deleted.',
  },
  {
    label: 'Reason for Deletion',
    description: 'Help us understand why you want your data deleted.',
  },
];

function DataDeletionRequest() {
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState('');
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [reason, setReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Here you would typically send a request to your backend
    // For now, we'll just simulate a successful request
    await new Promise(resolve => setTimeout(resolve, 2000));

    setIsSubmitting(false);
    setOpenSnackbar(true);
    setActiveStep(0);
    setEmail('');
    setDeleteAccount(false);
    setDeleteData(false);
    setReason('');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box display="flex" alignItems="center" mb={4}>
          <SecurityIcon color="primary" sx={{ fontSize: 40, mr: 2 }} />
          <Typography variant="h4" component="h1">
            Data Deletion Request
          </Typography>
        </Box>
        <Typography variant="body1" paragraph>
          We value your privacy. Follow the steps below to request the deletion of your data.
        </Typography>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box sx={{ mb: 2 }}>
                  {index === 0 && (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  )}
                  {index === 1 && (
                    <Box>
                      <FormControlLabel
                        control={<Checkbox checked={deleteAccount} onChange={(e) => setDeleteAccount(e.target.checked)} />}
                        label="Delete my entire account"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={deleteData} onChange={(e) => setDeleteData(e.target.checked)} />}
                        label="Delete specific data (we'll contact you for details)"
                      />
                    </Box>
                  )}
                  {index === 2 && (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="reason"
                      label="Reason for Deletion"
                      type="text"
                      id="reason"
                      multiline
                      rows={4}
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={index === steps.length - 1 ? handleSubmit : handleNext}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={isSubmitting}
                      >
                        {index === steps.length - 1 ? 'Submit' : 'Continue'}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Paper>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Your data deletion request has been submitted successfully. We'll process your request and contact you if we need any additional information.
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default DataDeletionRequest;
