import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Stepper, 
  Step, 
  StepLabel, 
  StepContent, 
  Button, 
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const steps = [
  {
    label: 'Project Details',
    description: 'Tell us about your project idea.',
    fields: [
      { name: 'projectName', label: 'Project Name', type: 'text' },
      { name: 'projectDescription', label: 'Project Description', type: 'textarea' },
      { name: 'industry', label: 'Industry', type: 'select', options: ['Healthcare', 'Finance', 'Education', 'Entertainment', 'E-commerce', 'Other'] }
    ]
  },
  {
    label: 'Technical Requirements',
    description: 'Specify your technical needs.',
    fields: [
      { name: 'platform', label: 'Platform', type: 'select', options: ['Web App', 'Mobile App', 'Desktop App', 'Cross-platform'] },
      { name: 'features', label: 'Key Features', type: 'textarea' },
      { name: 'timeline', label: 'Expected Timeline', type: 'text' }
    ]
  },
  {
    label: 'Contact Information',
    description: 'Provide your contact details.',
    fields: [
      { name: 'name', label: 'Your Name', type: 'text' },
      { name: 'email', label: 'Email Address', type: 'email' },
      { name: 'phone', label: 'Phone Number', type: 'tel' }
    ]
  },
];

function Start() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({});
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    // Here you would typically send the formData to your backend
    console.log(formData);
    // For now, we'll just move to the next step
    handleNext();
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Start Your Project
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Follow the steps below to begin your journey with CurrentlyRemote.com. We're excited to bring your ideas to life!
      </Typography>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <Typography variant="h6">{step.label}</Typography>
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  {step.fields.map((field) => (
                    <Grid item xs={12} key={field.name}>
                      {field.type === 'select' ? (
                        <FormControl fullWidth>
                          <InputLabel>{field.label}</InputLabel>
                          <Select
                            name={field.name}
                            value={formData[field.name] || ''}
                            onChange={handleInputChange}
                          >
                            {field.options.map((option) => (
                              <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <TextField
                          fullWidth
                          name={field.name}
                          label={field.label}
                          type={field.type}
                          multiline={field.type === 'textarea'}
                          rows={field.type === 'textarea' ? 4 : 1}
                          value={formData[field.name] || ''}
                          onChange={handleInputChange}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={index === steps.length - 1 ? handleSubmit : handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? 'Finish' : 'Continue'}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Start Another Project
          </Button>
        </Paper>
      )}
    </Container>
  );
}

export default Start;
