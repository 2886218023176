import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function Terms() {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Terms of Service
      </Typography>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>1. Acceptance of Terms</Typography>
        <Typography variant="body1" paragraph>
          By accessing or using our services, you agree to be bound by these Terms of Service and all applicable laws and regulations.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>2. Use of Services</Typography>
        <Typography variant="body1" paragraph>
          You agree to use our services only for lawful purposes and in accordance with these Terms. You are responsible for maintaining the confidentiality of your account information.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>3. Intellectual Property</Typography>
        <Typography variant="body1" paragraph>
          All content and materials available through our services are the property of CurrentlyRemote.com or its licensors and are protected by copyright, trademark, and other intellectual property laws.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>4. Limitation of Liability</Typography>
        <Typography variant="body1" paragraph>
          CurrentlyRemote.com shall not be liable for any indirect, incidental, special, consequential or punitive damages resulting from your use of or inability to use our services.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>5. Modifications to Terms</Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to modify these Terms at any time. Your continued use of our services after any changes indicates your acceptance of the modified Terms.
        </Typography>
      </Box>
      <Typography variant="body2" color="text.secondary">
        Last updated: {new Date().toLocaleDateString()}
      </Typography>
    </Container>
  );
}

export default Terms;
