// src/pages/Home.js

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Chip,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  IconButton,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme
} from '@mui/material';
import * as Icons from '@mui/icons-material';
import { siteContent } from '../data/siteContent';

function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedIndustry, setSelectedIndustry] = useState('All');
  const [selectedCategory, setSelectedCategory] = useState('All');

  const industries = ['Healthcare', 'Finance', 'Education', 'Entertainment', 'E-commerce'];
  const [currentIndustryIndex, setCurrentIndustryIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentIndustryIndex((prevIndex) => (prevIndex + 1) % industries.length);
        setFadeIn(true);
      }, 500); // Half of the transition time
    }, 3000); // Change industry every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  const currentIndustry = industries[currentIndustryIndex];

  const { meta, home } = siteContent;
  const { hero, portfolio, challenges, process, testimonials, cta } = home;

  const filteredPortfolio = portfolio.projects.filter(app => 
    (selectedIndustry === 'All' || app.industry === selectedIndustry) &&
    (selectedCategory === 'All' || app.category === selectedCategory)
  );

  const [selectedProject, setSelectedProject] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenModal = (project) => {
    setSelectedProject(project);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
  };

  return (
    <Box sx={{ bgcolor: theme.palette.background.default }}>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />
        <link rel="canonical" href="https://currentlyremote.com" />
      </Helmet>

      {/* Hero Section */}
      <Box sx={{ 
        py: 10, 
        background: 'linear-gradient(135deg, #0f2027 0%, #203a43 50%, #2c5364 100%)',
        color: '#fff',
        position: 'relative',
        overflow: 'hidden',
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center" direction={isMobile ? 'column-reverse' : 'row'}>
            {/* Left side - Image */}
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="https://images.unsplash.com/photo-1690297216767-996fd7f15495?q=80&w=1587&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="App Development"
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '10px',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.25)',
                }}
              />
            </Grid>
            
            {/* Right side - Text */}
            <Grid item xs={12} md={6}>
              <Box textAlign={isMobile ? 'center' : 'left'}>
                <Typography variant={isMobile ? 'h3' : 'h2'} gutterBottom fontWeight="bold" sx={{ mb: 2 }}>
                  Transforming{' '}
                  <Fade in={fadeIn} timeout={1000}>
                    <Box component="span" sx={{ color: theme.palette.secondary.main, display: 'inline-block', minWidth: '200px' }}>
                      {currentIndustry}
                    </Box>
                  </Fade>
                  {' '}Ideas into Tap-worthy Apps
                </Typography>
                <Typography variant={isMobile ? 'h6' : 'h5'} gutterBottom sx={{ mb: 4 }}>
                  {hero.subtitle}
                </Typography>
                <Typography variant="body1" paragraph>
                  {hero.description}
                </Typography>
                <Button 
                  variant="contained" 
                  color="secondary" 
                  size="large" 
                  href={hero.ctaLink}
                  sx={{ 
                    mb: 4,
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 20px rgba(0,0,0,0.25)',
                    }
                  }}
                >
                  {hero.ctaText}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Portfolio Section */}
      <Container sx={{ py: 8 }} maxWidth="lg">
        <Typography component="h2" variant="h4" align="center" gutterBottom>
          {portfolio.title}
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          {portfolio.description}
        </Typography>
        
        {/* Filters */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" align="center" gutterBottom>
            Filter by:
          </Typography>
          <Stack 
            direction={isMobile ? 'column' : 'row'} 
            spacing={2} 
            justifyContent="center" 
            alignItems="center"
          >
            <Stack 
              direction="row" 
              spacing={1} 
              flexWrap="wrap" 
              justifyContent="center" 
              sx={{ mb: isMobile ? 2 : 0 }}
            >
              {portfolio.industries.map((industry) => (
                <Chip
                  key={industry}
                  label={industry}
                  onClick={() => setSelectedIndustry(industry)}
                  color={selectedIndustry === industry ? "primary" : "default"}
                  sx={{ m: 0.5 }}
                />
              ))}
            </Stack>
            <Stack 
              direction="row" 
              spacing={1} 
              flexWrap="wrap" 
              justifyContent="center"
            >
              {portfolio.categories.map((category) => (
                <Chip
                  key={category}
                  label={category}
                  onClick={() => setSelectedCategory(category)}
                  color={selectedCategory === category ? "secondary" : "default"}
                  sx={{ m: 0.5 }}
                />
              ))}
            </Stack>
          </Stack>
        </Box>

        {/* Portfolio Grid */}
        <Grid container spacing={4}>
          {filteredPortfolio.map((project, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card 
                sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.03)',
                  }
                }}
              >
                <CardMedia
                  component="img"
                  image={project.image}
                  alt={project.name}
                  sx={{ height: 140 }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h3">
                    {project.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {project.industry} | {project.category}
                  </Typography>
                  <Typography>
                    {project.description}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                  <Button size="small" color="primary" onClick={() => handleOpenModal(project)}>Learn More</Button>
                  <Box>
                    {project.android && (
                      <IconButton 
                        color="primary" 
                        component="a" 
                        href={project.android} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        aria-label="Download on Google Play"
                      >
                        <Icons.Android />
                      </IconButton>
                    )}
                    {project.ios && (
                      <IconButton 
                        color="primary" 
                        component="a" 
                        href={project.ios} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        aria-label="Download on App Store"
                      >
                        <Icons.Apple />
                      </IconButton>
                    )}
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Project Details Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={selectedProject !== null}
        onClose={handleCloseModal}
        aria-labelledby="project-dialog-title"
      >
        {selectedProject && (
          <>
            <DialogTitle id="project-dialog-title">
              {selectedProject.name}
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Icons.Close />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{ mb: 2 }}>
                <img src={selectedProject.image} alt={selectedProject.name} style={{ width: '100%', borderRadius: '4px' }} />
              </Box>
              <Typography gutterBottom>
                <strong>Industry:</strong> {selectedProject.industry}
              </Typography>
              <Typography gutterBottom>
                <strong>Category:</strong> {selectedProject.category}
              </Typography>
              <Typography gutterBottom>
                {selectedProject.description}
              </Typography>
              {selectedProject.features && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    Key Features:
                  </Typography>
                  <ul>
                    {selectedProject.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </>
              )}
              {selectedProject.technologies && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    Technologies Used:
                  </Typography>
                  <Typography>
                    {selectedProject.technologies.join(', ')}
                  </Typography>
                </>
              )}
            </DialogContent>
            <DialogActions>
              {selectedProject.android && (
                <Button startIcon={<Icons.Android />} href={selectedProject.android} target="_blank" rel="noopener noreferrer">
                  Download on Google Play
                </Button>
              )}
              {selectedProject.ios && (
                <Button startIcon={<Icons.Apple />} href={selectedProject.ios} target="_blank" rel="noopener noreferrer">
                  Download on App Store
                </Button>
              )}
              {selectedProject.web && (
                <Button href={selectedProject.web} target="_blank" rel="noopener noreferrer">
                  Visit Website
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Challenges Section */}
      <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
        <Container maxWidth="lg">
          <Typography component="h2" variant="h4" align="center" gutterBottom>
            {challenges.title}
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            {challenges.description}
          </Typography>
          <Grid container spacing={4}>
            {challenges.items.map((challenge, index) => (
              <Grid item key={index} xs={12} md={4}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h3">
                      {challenge.title}
                    </Typography>
                    <Typography>{challenge.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Process Section */}
      <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
        <Container maxWidth="lg">
          <Typography component="h2" variant="h4" align="center" gutterBottom>
            {process.title}
          </Typography>
          <Stepper orientation={isMobile ? "vertical" : "horizontal"} alternativeLabel={!isMobile}>
            {process.steps.map((step, index) => (
              <Step key={index} active={true}>
                <StepLabel
                  StepIconComponent={() => (
                    <Paper
                      elevation={3}
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}
                    >
                      {index + 1}
                    </Paper>
                  )}
                >
                  <Typography variant="h6">{step.title}</Typography>
                </StepLabel>
                <StepContent>
                  <Typography>{step.description}</Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
        <Container maxWidth="lg">
          <Typography component="h2" variant="h4" align="center" gutterBottom>
            {testimonials.title}
          </Typography>
          <Grid container spacing={4}>
            {testimonials.items.map((testimonial, index) => (
              <Grid item key={index} xs={12} md={6}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="body1" paragraph>
                      "{testimonial.quote}"
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      - {testimonial.author}, {testimonial.company}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* CTA Section */}
      <Container sx={{ py: 8 }} maxWidth="md">
        <Typography component="h2" variant="h4" align="center" gutterBottom>
          {cta.title}
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          {cta.description}
        </Typography>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" color="primary" size="large" href={cta.buttonLink}>
            {cta.buttonText}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Home;
