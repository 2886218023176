import healthtrackImage from '../assets/date.png';
import edulearnImage from '../assets/drive.png';
import fintrackImage from '../assets/house.png';
import streamflixImage from '../assets/hunt.png';
import shopeaseImage from '../assets/sitter.png';
import taskmasterImage from '../assets/tickets.png';
import fitnesspalImage from '../assets/uk.png';
import travelbuddyImage from '../assets/date.png';
import codecollabImage from '../assets/drive.png';
import greenthumbImage from '../assets/drive.png';

export const siteContent = {
  meta: {
    title: "CurrentlyRemote.com | Transforming Ideas into Tap-worthy Apps",
    description: "CurrentlyRemote.com specializes in creating innovative, user-friendly mobile and web applications. From concept to launch, we bring your ideas to life.",
    keywords: "app development, mobile apps, web applications, software development, remote work, digital innovation",
  },
  header: {
    title: "CurrentlyRemote.com",
    links: [
      { to: "/", icon: "FaHome", label: "Home" },
      { to: "/projects", icon: "FaProjectDiagram", label: "Projects" },
      { to: "/services", icon: "FaCogs", label: "Services" },
      { to: "/how-we-work", icon: "FaUsers", label: "How We Work" },
      { to: "/about", icon: "FaInfoCircle", label: "About Us" },
      { to: "/team", icon: "FaUsers", label: "Team" },
      { to: "/start", icon: "FaPlay", label: "Start" },
      { to: "/privacy", icon: "FaLock", label: "Privacy Policy" },
      { to: "/feedback", icon: "FaCommentDots", label: "Feedback" }
    ]
  },
  home: {
    hero: {
      title: "Transforming Ideas into Tap-worthy Apps",
      subtitle: "Where Innovation Meets User Experience",
      description: "At CurrentlyRemote.com, we specialize in turning your visionary ideas into reality. Our team of expert developers and designers create intuitive, engaging, and high-performance applications that users love.",
      ctaText: "Let's Create Together",
      ctaLink: "/start"
    },
    portfolio: {
      title: "Our Portfolio",
      description: "Explore our diverse range of successful projects across various industries:",
      industries: ['All', 'Healthcare', 'Finance', 'Education', 'Entertainment', 'E-commerce', 'Productivity', 'Health & Fitness', 'Travel', 'Technology', 'IoT'],
      categories: ['All', 'Mobile App', 'Web App', 'Desktop App', 'Cross-platform'],
      projects: [
        { 
          name: "HealthTrack Pro", 
          image: healthtrackImage, 
          description: "A comprehensive health monitoring app for patients and healthcare providers.", 
          android: "https://play.google.com/store/apps/details?id=com.currentlyremote.healthtrackpro", 
          ios: "https://apps.apple.com/us/app/healthtrackpro/id123456789",
          industry: "Healthcare",
          category: "Mobile App",
          features: [
            "Real-time health data monitoring",
            "Medication reminders",
            "Secure communication with healthcare providers",
            "Customizable health goals"
          ],
          technologies: ["React Native", "Firebase", "HealthKit", "Google Fit API"]
        },
        { 
          name: "EduLearn Platform", 
          image: edulearnImage, 
          description: "An interactive e-learning platform for students and educators.", 
          web: "https://edulearn.currentlyremote.com",
          industry: "Education",
          category: "Web App"
        },
        {
          name: "FinTrack",
          image: fintrackImage,
          description: "A personal finance management app with budgeting and investment tracking features.",
          android: "https://play.google.com/store/apps/details?id=com.currentlyremote.fintrack",
          ios: "https://apps.apple.com/us/app/fintrack/id987654321",
          industry: "Finance",
          category: "Mobile App"
        },
        {
          name: "StreamFlix",
          image: streamflixImage,
          description: "A video streaming platform with personalized recommendations and social features.",
          web: "https://streamflix.currentlyremote.com",
          industry: "Entertainment",
          category: "Web App"
        },
        {
          name: "ShopEase",
          image: shopeaseImage,
          description: "An e-commerce platform with AR product visualization and one-click checkout.",
          web: "https://shopease.currentlyremote.com",
          android: "https://play.google.com/store/apps/details?id=com.currentlyremote.shopease",
          ios: "https://apps.apple.com/us/app/shopease/id135792468",
          industry: "E-commerce",
          category: "Cross-platform"
        },
        {
          name: "TaskMaster",
          image: taskmasterImage,
          description: "A productivity app for teams with task management, time tracking, and reporting.",
          web: "https://taskmaster.currentlyremote.com",
          android: "https://play.google.com/store/apps/details?id=com.currentlyremote.taskmaster",
          ios: "https://apps.apple.com/us/app/taskmaster/id246813579",
          industry: "Productivity",
          category: "Cross-platform"
        },
        {
          name: "FitnessPal",
          image: fitnesspalImage,
          description: "A fitness app with personalized workout plans, nutrition tracking, and community challenges.",
          android: "https://play.google.com/store/apps/details?id=com.currentlyremote.fitnesspal",
          ios: "https://apps.apple.com/us/app/fitnesspal/id369852147",
          industry: "Health & Fitness",
          category: "Mobile App"
        },
        {
          name: "TravelBuddy",
          image: travelbuddyImage,
          description: "An AI-powered travel planning app with itinerary suggestions and local experiences.",
          android: "https://play.google.com/store/apps/details?id=com.currentlyremote.travelbuddy",
          ios: "https://apps.apple.com/us/app/travelbuddy/id741852963",
          industry: "Travel",
          category: "Mobile App"
        },
        {
          name: "CodeCollab",
          image: codecollabImage,
          description: "A real-time collaborative coding platform for remote development teams.",
          web: "https://codecollab.currentlyremote.com",
          industry: "Technology",
          category: "Web App"
        },
        {
          name: "GreenThumb",
          image: greenthumbImage,
          description: "An IoT-enabled smart gardening app for plant care and automation.",
          android: "https://play.google.com/store/apps/details?id=com.currentlyremote.greenthumb",
          ios: "https://apps.apple.com/us/app/greenthumb/id852741963",
          industry: "IoT",
          category: "Mobile App"
        }
      ]
    },
    challenges: {
      title: "Challenges We'll Solve",
      description: "We're here to tackle your toughest app development challenges:",
      items: [
        {
          title: "Filling gaps in your team",
          description: "Our skilled developers seamlessly integrate with your existing team, bringing expertise where you need it most."
        },
        {
          title: "Ensuring your idea works",
          description: "We validate your concept through thorough market research and prototyping to ensure viability and user appeal."
        },
        {
          title: "Transforming your idea into a software product",
          description: "From concept to launch, we guide you through every step of turning your vision into a fully-functional, market-ready product."
        }
      ]
    },
    process: {
      title: "Our Development Process",
      steps: [
        { title: "Discovery", description: "We dive deep into your idea, researching the market and defining clear objectives." },
        { title: "Design", description: "Our designers create intuitive, visually appealing interfaces that users love." },
        { title: "Development", description: "Our expert coders bring your app to life using cutting-edge technologies." },
        { title: "Testing", description: "Rigorous quality assurance ensures your app is bug-free and performs flawlessly." },
        { title: "Launch", description: "We handle the complexities of app store submissions and web deployments." },
        { title: "Maintenance", description: "Ongoing support and updates keep your app running smoothly and evolving with user needs." }
      ]
    },
    testimonials: {
      title: "What Our Clients Say",
      items: [
        {
          quote: "CurrentlyRemote.com transformed our business idea into a sleek, user-friendly app that our customers love.",
          author: "Jane Doe",
          company: "TechStart Inc."
        },
        {
          quote: "The team's expertise and dedication to our project was impressive. They delivered beyond our expectations.",
          author: "John Smith",
          company: "HealthInnovate LLC"
        }
      ]
    },
    cta: {
      title: "Ready to Bring Your App Idea to Life?",
      description: "Let's discuss how we can turn your vision into a successful digital product.",
      buttonText: "Start Your Project",
      buttonLink: "/start"
    }
  }
};
