import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  CardActions, 
  Button, 
  Box,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Icons from '@mui/icons-material';
import { siteContent } from '../data/siteContent';

function Projects() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState('All');
  const [selectedCategory, setSelectedCategory] = useState('All');

  const { portfolio } = siteContent.home;

  // Create arrays of unique industries and categories
  const industries = ['All', ...new Set(portfolio.projects.map(project => project.industry))];
  const categories = ['All', ...new Set(portfolio.projects.map(project => project.category))];

  const handleOpenModal = (project) => {
    setSelectedProject(project);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
  };

  const handleIndustryFilter = (industry) => {
    setSelectedIndustry(industry);
  };

  const handleCategoryFilter = (category) => {
    setSelectedCategory(category);
  };

  const filteredProjects = portfolio.projects.filter(project => 
    (selectedIndustry === 'All' || project.industry === selectedIndustry) &&
    (selectedCategory === 'All' || project.category === selectedCategory)
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Our Projects
      </Typography>
      <Typography variant="body1" paragraph>
        Explore our diverse range of successful projects across various industries:
      </Typography>

      {/* Filters */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>Filter by Industry:</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
          {industries.map((industry) => (
            <Chip
              key={industry}
              label={industry}
              onClick={() => handleIndustryFilter(industry)}
              color={selectedIndustry === industry ? 'primary' : 'default'}
            />
          ))}
        </Box>
        <Typography variant="h6" gutterBottom>Filter by Category:</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {categories.map((category) => (
            <Chip
              key={category}
              label={category}
              onClick={() => handleCategoryFilter(category)}
              color={selectedCategory === category ? 'primary' : 'default'}
            />
          ))}
        </Box>
      </Box>

      {/* Projects Grid */}
      <Grid container spacing={4}>
        {filteredProjects.map((project, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                height="140"
                image={project.image}
                alt={project.name}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div">
                  {project.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {project.industry} | {project.category}
                </Typography>
                <Typography variant="body2">
                  {project.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => handleOpenModal(project)}>Learn More</Button>
                {project.android && (
                  <IconButton 
                    size="small" 
                    color="primary" 
                    component="a" 
                    href={project.android} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <Icons.Android />
                  </IconButton>
                )}
                {project.ios && (
                  <IconButton 
                    size="small" 
                    color="primary" 
                    component="a" 
                    href={project.ios} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <Icons.Apple />
                  </IconButton>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Project Details Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={selectedProject !== null}
        onClose={handleCloseModal}
        aria-labelledby="project-dialog-title"
      >
        {selectedProject && (
          <>
            <DialogTitle id="project-dialog-title">
              {selectedProject.name}
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Icons.Close />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{ mb: 2 }}>
                <img src={selectedProject.image} alt={selectedProject.name} style={{ width: '100%', borderRadius: '4px' }} />
              </Box>
              <Typography gutterBottom>
                <strong>Industry:</strong> {selectedProject.industry}
              </Typography>
              <Typography gutterBottom>
                <strong>Category:</strong> {selectedProject.category}
              </Typography>
              <Typography gutterBottom>
                {selectedProject.description}
              </Typography>
              {selectedProject.features && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    Key Features:
                  </Typography>
                  <ul>
                    {selectedProject.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </>
              )}
              {selectedProject.technologies && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    Technologies Used:
                  </Typography>
                  <Typography>
                    {selectedProject.technologies.join(', ')}
                  </Typography>
                </>
              )}
            </DialogContent>
            <DialogActions>
              {selectedProject.android && (
                <Button startIcon={<Icons.Android />} href={selectedProject.android} target="_blank" rel="noopener noreferrer">
                  Download on Google Play
                </Button>
              )}
              {selectedProject.ios && (
                <Button startIcon={<Icons.Apple />} href={selectedProject.ios} target="_blank" rel="noopener noreferrer">
                  Download on App Store
                </Button>
              )}
              {selectedProject.web && (
                <Button href={selectedProject.web} target="_blank" rel="noopener noreferrer">
                  Visit Website
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </Container>
  );
}

export default Projects;
