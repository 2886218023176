import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardHeader, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Code, Brush, PhoneAndroid, Language, Speed, Security } from '@mui/icons-material';

const services = [
  {
    title: "Custom Software Development",
    icon: <Code />,
    description: "Tailored software solutions to meet your unique business needs.",
    features: ["Web Applications", "Mobile Apps", "Desktop Software", "API Development"]
  },
  {
    title: "UI/UX Design",
    icon: <Brush />,
    description: "Creating intuitive and visually appealing user interfaces.",
    features: ["User Research", "Wireframing", "Prototyping", "Usability Testing"]
  },
  {
    title: "Mobile App Development",
    icon: <PhoneAndroid />,
    description: "Building responsive and feature-rich mobile applications.",
    features: ["iOS Development", "Android Development", "Cross-platform Apps", "App Store Optimization"]
  },
  {
    title: "Web Development",
    icon: <Language />,
    description: "Crafting modern and responsive websites and web applications.",
    features: ["Front-end Development", "Back-end Development", "E-commerce Solutions", "Content Management Systems"]
  },
  {
    title: "Performance Optimization",
    icon: <Speed />,
    description: "Enhancing the speed and efficiency of your digital products.",
    features: ["Code Optimization", "Database Tuning", "Caching Strategies", "Load Balancing"]
  },
  {
    title: "Cybersecurity Services",
    icon: <Security />,
    description: "Protecting your digital assets with robust security measures.",
    features: ["Security Audits", "Penetration Testing", "Secure Coding Practices", "Data Encryption"]
  }
];

function Services() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Our Services
      </Typography>
      <Typography variant="body1" paragraph>
        At CurrentlyRemote.com, we offer a comprehensive suite of digital services to help bring your ideas to life. Our team of experts is dedicated to delivering high-quality solutions tailored to your specific needs.
      </Typography>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                avatar={service.icon}
                title={service.title}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {service.description}
                </Typography>
                <List dense>
                  {service.features.map((feature, featureIndex) => (
                    <ListItem key={featureIndex}>
                      <ListItemIcon>
                        <Code fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Services;
