import React from 'react';
import { Container, Typography, Grid, Paper, Box } from '@mui/material';

function About() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        About CurrentlyRemote.com
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" paragraph>
            CurrentlyRemote.com is a leading digital solutions provider, specializing in transforming innovative ideas into reality. Founded in 2015, we've been at the forefront of the remote work revolution, leveraging global talent to deliver exceptional results for our clients.
          </Typography>
          <Typography variant="body1" paragraph>
            Our team of expert developers, designers, and project managers work collaboratively to create cutting-edge web and mobile applications, tailored to meet the unique needs of businesses across various industries.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Our Mission</Typography>
            <Typography variant="body1" paragraph>
              To empower businesses with innovative digital solutions that drive growth, enhance efficiency, and create meaningful user experiences.
            </Typography>
            <Typography variant="h6" gutterBottom>Our Vision</Typography>
            <Typography variant="body1" paragraph>
              To be the go-to partner for businesses seeking to thrive in the digital age, known for our creativity, technical excellence, and commitment to client success.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>Why Choose Us?</Typography>
        <Grid container spacing={2}>
          {['Expertise', 'Innovation', 'Reliability', 'Flexibility', 'Quality', 'Support'].map((value) => (
            <Grid item xs={12} sm={6} md={4} key={value}>
              <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="h6">{value}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default About;
