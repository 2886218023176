// src/components/Header.js

import React, { useState, useEffect } from 'react';
import { Box, Flex, Heading, Link, IconButton, useDisclosure, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, VStack, useMediaQuery } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaHome, FaLock, FaCommentDots, FaProjectDiagram, FaCogs, FaUsers, FaInfoCircle, FaPlay, FaBars } from 'react-icons/fa';
import { siteContent } from '../data/siteContent';

// Custom hook to track scroll direction
function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState("up");
  const [prevOffset, setPrevOffset] = useState(0);

  useEffect(() => {
    const toggleScrollDirection = () => {
      let scrollY = window.pageYOffset;
      if (scrollY === 0) {
        setScrollDirection("up");
      } else if (scrollY > prevOffset) {
        setScrollDirection("down");
      } else if (scrollY < prevOffset) {
        setScrollDirection("up");
      }
      setPrevOffset(scrollY);
    };
    window.addEventListener("scroll", toggleScrollDirection);
    return () => {
      window.removeEventListener("scroll", toggleScrollDirection);
    }
  }, [prevOffset]);

  return scrollDirection;
}

function Header() {
  const scrollDirection = useScrollDirection();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const iconMap = {
    FaHome, FaLock, FaCommentDots, FaProjectDiagram, FaCogs, FaUsers, FaInfoCircle, FaPlay
  };

  return (
    <Box
      bgGradient="linear(to-r, teal.500, blue.500)"
      color="white"
      py={4}
      shadow="md"
      position="fixed"
      top={0}
      left={0}
      right={0}
      transform={scrollDirection === "down" ? "translateY(-100%)" : "translateY(0)"}
      transition="transform 0.3s ease-in-out"
      zIndex={1000}
    >
      <Flex justify="space-between" align="center" maxW="1200px" mx="auto" px={4}>
        <Heading as="h1" size="lg">
          <Link as={RouterLink} to="/" _hover={{ textDecoration: 'none', color: 'teal.200' }}>
            {siteContent.header.title}
          </Link>
        </Heading>
        {isLargerThan768 ? (
          <Flex align="center">
            {siteContent.header.links.map((link, index) => (
              <HeaderLink key={index} to={link.to} icon={iconMap[link.icon]} label={link.label} />
            ))}
          </Flex>
        ) : (
          <IconButton
            icon={<FaBars />}
            variant="ghost"
            onClick={onOpen}
            aria-label="Open menu"
          />
        )}
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bgColor="white">
          <DrawerCloseButton color="gray.800" />
          <DrawerHeader color="gray.800">Menu</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              {siteContent.header.links.map((link, index) => (
                <MobileHeaderLink key={index} to={link.to} icon={iconMap[link.icon]} label={link.label} onClose={onClose} />
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

function HeaderLink({ to, icon, label }) {
  return (
    <Link as={RouterLink} to={to} display="flex" alignItems="center" px={3} _hover={{ textDecoration: 'none', color: 'teal.200' }}>
      <IconButton
        icon={icon}
        aria-label={label}
        variant="ghost"
        colorScheme="whiteAlpha"
        size="sm"
        mr={2}
      />
      {label}
    </Link>
  );
}

function MobileHeaderLink({ to, icon, label, onClose }) {
  return (
    <Link 
      as={RouterLink} 
      to={to} 
      display="flex" 
      alignItems="center" 
      onClick={onClose} 
      color="gray.800"
      _hover={{ textDecoration: 'none', color: 'teal.500' }}
    >
      <IconButton
        icon={icon}
        aria-label={label}
        variant="ghost"
        colorScheme="teal"
        size="lg"
        mr={2}
      />
      {label}
    </Link>
  );
}

export default Header;
