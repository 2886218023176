import React from 'react';
import { Container, Typography, Stepper, Step, StepLabel, StepContent, Paper } from '@mui/material';

const steps = [
  {
    label: 'Discovery',
    description: 'We start by understanding your vision, goals, and requirements. This phase involves in-depth discussions, market research, and feasibility studies.',
  },
  {
    label: 'Planning',
    description: 'Based on the discovery phase, we create a detailed project plan, including timelines, milestones, and resource allocation.',
  },
  {
    label: 'Design',
    description: 'Our designers create intuitive and visually appealing interfaces, ensuring a great user experience.',
  },
  {
    label: 'Development',
    description: 'Our skilled developers bring your project to life, using the latest technologies and best practices.',
  },
  {
    label: 'Testing',
    description: 'Rigorous testing is performed to ensure your product is bug-free and performs optimally.',
  },
  {
    label: 'Deployment',
    description: 'We handle the launch process, ensuring a smooth deployment of your product.',
  },
  {
    label: 'Maintenance and Support',
    description: 'We provide ongoing support and maintenance to keep your product running smoothly and up-to-date.',
  },
];

function HowWeWork() {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        How We Work
      </Typography>
      <Typography variant="body1" paragraph>
        At CurrentlyRemote.com, we follow a structured and transparent process to ensure the success of your project. Here's an overview of our workflow:
      </Typography>
      <Stepper orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} active={true}>
            <StepLabel>
              <Typography variant="h6">{step.label}</Typography>
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Container>
  );
}

export default HowWeWork;
